// import homeBanner1 from "../images/home/home-1.webp"
// import homeBanner2 from "../images/home/home-2.webp"
// import homeBanner3 from "../images/home/home-3.webp"

import homeBanner1 from "../images/home/banner1.png"
import homeBanner2 from "../images/home/banner2.jpg"
import homeBanner3 from "../images/home/banner3.jpg"
import homeBanner4 from "../images/home/banner4.png"

import pic1 from "../images/blog/grid/pic1.jpg"
import pic2 from "../images/blog/grid/pic2.jpg"
import pic3 from "../images/blog/grid/pic3.jpg"

import abt1 from "../images/about/carousel/1.webp"
import abt2 from "../images/about/carousel/2.webp"
import abt3 from "../images/about/carousel/3.webp"
import abt4 from "../images/about/carousel/4.webp"
import abt6 from "../images/about/carousel/6.webp"
import abt7 from "../images/about/carousel/7.webp"
import abt8 from "../images/about/carousel/8.webp"
import abt9 from "../images/about/carousel/9.webp"
import abt10 from "../images/about/carousel/10.webp"
import abt11 from "../images/about/carousel/11.webp"
import abt12 from "../images/about/carousel/12.webp"
import abt13 from "../images/about/carousel/13.webp"
import abt14 from "../images/about/carousel/14.webp"
import abt15 from "../images/about/carousel/15.webp"
import abt16 from "../images/about/carousel/16.webp"
import abt17 from "../images/about/carousel/17.webp"
import abt19 from "../images/about/carousel/19.webp"
import abt20 from "../images/about/carousel/20.webp"
import abt21 from "../images/about/carousel/21.webp"
import abt22 from "../images/about/carousel/22.webp"
import abt25 from "../images/about/carousel/25.webp"
import abt26 from "../images/about/carousel/26.webp"
import abt27 from "../images/about/carousel/27.webp"

import cer1 from "../images/about/qualifications/cer-1.webp"
import cer2 from "../images/about/qualifications/cer-2.webp"
import cer3 from "../images/about/qualifications/cer-3.webp"
import cer4 from "../images/about/qualifications/cer-4.webp"
import cer5 from "../images/about/qualifications/cer-5.webp"
import cer6 from "../images/about/qualifications/cer-6.webp"
import cer7 from "../images/about/qualifications/cer-7.webp"
import cer8 from "../images/about/qualifications/cer-8.webp"

import recFrostSullivan from "../images/about/recognition/recog-frost-sullivan.png"
import recogBiopharma from "../images/about/recognition/recog-biopharma-awards.gif"
import rec0 from "../images/about/recognition/recog-0.png"
import rec1 from "../images/about/recognition/recog-1.webp"
import rec2 from "../images/about/recognition/recog-2.webp"
import rec3 from "../images/about/recognition/recog-3.webp"
import rec4 from "../images/about/recognition/recog-4.webp"
import rec5 from "../images/about/recognition/recog-5.webp"
import rec6 from "../images/about/recognition/recog-6.webp"
import rec7 from "../images/about/recognition/recog-7.webp"
import rec8 from "../images/about/recognition/recog-8.webp"

export const servicesContentCustom2 = [
  {
    image: recogBiopharma,
    description: `"The Most Innovative Biotech" Award in the 2nd Annual Biopharma Asia Industry Awards by BioPharma Asia in 2012`
  },
  {
    image: recFrostSullivan,
    description: `"2012 Frost & Sullivan Asia Pacific Fastest Growing Home-Grown Company in Oncology Care" by Frost & Sullivan Asia Pacific Best Practices Awards in 2012`
  },
  {
    image: recFrostSullivan,
    description: `"2010 Frost & Sullivan Asia Pacific Emerging Company of the Year" by Frost & Sullivan Asia Pacific Best Practices Awards in 2010`
  }
  // {
  //   image: rec7,
  //   description:
  //     "Appreciation from Regulatory & Quality Forum X to PT Kalbio Global Medika (Mab Line) regarding GMP Certification for Pharmaceutical",
  //   serviceName: "2021 GMP Certification for Pharmaceutical",
  // },
  // {
  //   image: rec8,
  //   description:
  //     "Appreciation from Regulatory & Quality Forum X to PT Kalbio Global Medika in recognition of compliance to Kalbe Corporate Manual",
  //   serviceName: "2021 100% Integration to Corporate Regulatory Manual",
  // },
  // {
  //   image: rec6,
  //   description:
  //     "Award for PT Kalbio Global Medika in achieving the completion of Quality Assurance Agreement",
  //   serviceName: "2019 Regulatory & Quality Award",
  // },
  // {
  //   image: rec1,
  //   description:
  //     "Honorable mentions award to PT Kalbio Global Medika from International Society for Pharmaceutical Engineering (ISPE)",
  //   serviceName: "2019 IMAPAC Bioprocessing Award",
  // },
  // {
  //   image: rec3,
  //   description:
  //     "Award from Ministry of Health of the Republic of Indonesia for institution which contributes in the development of health industry.",
  //   serviceName:
  //     "Employee Awards for Domestic Creation in Pharmaceuticals and Health Equipments",
  // },
  // {
  //   image: rec5,
  //   description:
  //     "Appreciation for PT Kalbio Global Medika as the process optimization in EPO production team at Kalbe Group innovation convention for process optimization in EPO production",
  //   serviceName: "2018 Innovation Convention Appreciation",
  // },
  // {
  //   image: rec2,
  //   description:
  //     "Award from IMAPAC for recognition to PT Kalbio Global Medika as exceptional bioprocessing experts, organizations and technologies that facilitate biomanufacturing excellence at enhanced speed, reduced cost, and superior quality",
  //   serviceName: "2017 ISPE Facility of the Year",
  // },
  // {
  //   image: rec4,
  //   description:
  //     "Award for PT Kalbio Global Medika as the most promising new biologics facility in Indonesia at the Asia-Pacific Bioprocessing Excellence Awards 2017",
  //   serviceName: "Most Promising New Biologics Facility in Indonesia",
  // },
]

export const servicesContentCustom1 = [
  {
    image: cer5,
    description:
      "Quality Assurance Audit Certificate from PCI Pharma Services.",
    serviceName: "EU GMP Compliance",
  },
  {
    image: cer6,
    description:
      "Investigational Medicinal Products (IMP) Manufactured in Third countries (article 13(3)(b) of directive 2001/20/EC. IMP Includes: Hemapo, Efepoetin and Epoetin-alpha injection.",
    serviceName: "Qualified Person's Declaration Equivalence to EU GMP",
  },
  {
    image: cer1,
    description:
      "For non-sterile efepoetin alfa manifacturing from National Agency for Drug and Food Control Republic of Indonesia (BPOM).",
    serviceName:
      "Good Manifacturing Practice for Active Pharmaceutical Ingredients (API) Certificate",
  },
  {
    image: cer2,
    description:
      "For non-sterile erythropoietin manifacturing from National Agency for Drug and Food Control Republic of Indonesia (BPOM).",
    serviceName:
      "Good Manifacturing Practice for Active Pharmaceutical Ingredients (API) Certificate",
  },
  {
    image: cer3,
    description:
      "For Non-betalactam Small Volume Injection from National Agency for Drug and Food Control Republic of Indonesia (BPOM).",
    serviceName: "Good Manifacturing Practice for Small Volume Injection",
  },
  {
    image: cer8,
    description:
      "For Biotechnology Products Non-vaccine from Indonesian Food and Drug Authority (BPOM).",
    serviceName:
      "Good Manufacturing Practice for Biotechnology Products Non-vaccine",
  },
  {
    image: cer7,
    description:
      "Commitment to the delivery of safe and effective products and services to patients and consumers.",
    serviceName: "Corporate Quality Policy",
  },
  {
    image: cer4,
    description:
      "Initiatives of PT.Kalbio Global Medika to ensure the product ingredients and manifacturing process are comply to halal.",
    serviceName: "Halal Compliance",
  },
]

export const blogContent1 = [
  {
    image: require("../images/blog/grid/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../images/blog/grid/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../images/blog/grid/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../images/blog/grid/pic1.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../images/blog/grid/pic2.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: require("../images/blog/grid/pic3.jpg"),
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
]

export const blogContent3 = [
  {
    image: pic1,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: pic2,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: pic3,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: pic1,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: pic2,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
  {
    image: pic3,
    title: "Seven Doubts You Should",
    description:
      "All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true.",
    author: "Jack",
  },
]

export const servicesContent1 = [
  {
    serviceName: "Wind Turbines",
    icon: "flaticon-worker",
  },
  {
    serviceName: "Solar Panels",
    icon: "flaticon-factory",
  },
  {
    serviceName: "Maintenance",
    icon: "flaticon-settings",
  },
  {
    serviceName: "Maintenance",
    icon: "flaticon-engineer-1",
  },
]

export const serviceContent2 = [
  {
    serviceName: "Material Engineering",
    icon: "flaticon-worker",
  },
  {
    serviceName: "Power and Energy",
    icon: "flaticon-factory",
  },
  {
    serviceName: "Agricultural Engineering",
    icon: "flaticon-settings",
  },
  {
    serviceName: "Petroleum Engineering",
    icon: "flaticon-engineer-1",
  },
  {
    serviceName: "Material Engineering",
    icon: "flaticon-worker",
  },
]

export const homeSliderContent2 = [
  {
    image: abt1,
    title: "",
    title2: "",
  },
  {
    image: abt2,
    title: "",
    title2: "",
  },
  {
    image: abt3,
    title: "",
    title2: "",
  },
  {
    image: abt4,
    title: "",
    title2: "",
  },
  {
    image: abt6,
    title: "",
    title2: "",
  },
  {
    image: abt7,
    title: "",
    title2: "",
  },
  {
    image: abt8,
    title: "",
    title2: "",
  },
  {
    image: abt9,
    title: "",
    title2: "",
  },
  {
    image: abt10,
    title: "",
    title2: "",
  },
  {
    image: abt11,
    title: "",
    title2: "",
  },
  {
    image: abt12,
    title: "",
    title2: "",
  },
  {
    image: abt13,
    title: "",
    title2: "",
  },
  {
    image: abt14,
    title: "",
    title2: "",
  },
  {
    image: abt15,
    title: "",
    title2: "",
  },
  {
    image: abt16,
    title: "",
    title2: "",
  },
  {
    image: abt17,
    title: "",
    title2: "",
  },
  {
    image: abt19,
    title: "",
    title2: "",
  },
  {
    image: abt20,
    title: "",
    title2: "",
  },
  {
    image: abt21,
    title: "",
    title2: "",
  },
  {
    image: abt22,
    title: "",
    title2: "",
  },
  {
    image: abt25,
    title: "",
    title2: "",
  },
  {
    image: abt26,
    title: "",
    title2: "",
  },
  {
    image: abt27,
    title: "",
    title2: "",
  },
]

export const HomeBannerSliderContent = [
  {
    image: homeBanner4,
    // title: "Leading The Way",
    // description:
    //   "Clear, decisive direction from the leadership at Innogene Kalbiotech paves the way for breakthroughs and advancements in developing new solutions and products",
  },

  {
    image: homeBanner2,
    title: "Admired & Respected",
    description:
      "Innogene is globally well respected and renowned for its culture of innovation and expertise within the biopharmaceutical industry",
  },
  {
    image: homeBanner3,
    title: "We Know Asia",
    description:
      "Innogene Kalbiotech is firmly rooted in Asia, leveraging our strength, experience and global reputation as a leader. We’ve built international strategic marketing and distribution networks across emerging markets",
  },
]
